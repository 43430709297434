import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledFooter = styled.footer`
  max-width: 1200px;
  margin: 20px auto;
  nav {
    display: flex;
    justify-content: center;
    a {
      text-decoration: none;
      color: #fff;
    }
  }
`;

export default function Footer() {
  return (
    <StyledFooter>
      <nav>
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
      </nav>
    </StyledFooter>
  );
}
