import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Normalize } from 'styled-normalize';

import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { IntlProvider } from 'react-intl';
import 'intl';

import PumaCss from '../styles/PumaCss';
import Navigation from '../components/navigation';
import Footer from '../components/footer';

const Layout = ({ children, location, i18nMessages }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            languages {
              defaultLangKey
              langs
            }
          }
        }
      }
    `}
    render={data => {
      const url = location.pathname;
      const { langs, defaultLangKey } = data.site.siteMetadata.languages;
      const langKey = getCurrentLangKey(langs, defaultLangKey, url);
      const homeLink = `/${langKey}`.replace(`/${defaultLangKey}/`, '/');
      const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map(item => ({
        ...item,
        link: item.link.replace(`/${defaultLangKey}/`, '/'),
      }));

      return (
        <IntlProvider locale={langKey} messages={i18nMessages}>
          <>
            <Normalize />
            <PumaCss />
            <Navigation langs={langsMenu} langKey={langKey} />
            <main>{children}</main>
            {/* <Footer /> */}
          </>
        </IntlProvider>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  i18nMessages: PropTypes.object.isRequired,
};

export default Layout;
