import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Image from './image';
import jp from './japan.svg';
import en from './united-states.svg';

const Header = styled.header`
  position: fixed;
  background: #000;
  z-index: 9999;
  color: #f0f4f8;
  top: 0;
  h1 {
    margin: 0;
    width: 75px;
    @media (max-width: 600px) {
      width: 60px;
    }
  }

  width: 100%;
`;

const Wrapper = styled.div`
  padding: 5px;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const Nav = styled.nav`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  @media (max-width: 600px) {
    font-size: 14px;
    .featured-desktop {
      display: none;
    }
  }
  li {
    padding: 5px;
    margin: 10px;
    border-radius: 5px;
    @media (max-width: 600px) {
      padding: 10px;
    }
  }
  a {
    color: #f0f4f8;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 1.4px;
  }
`;

const LangLi = styled.li`
  ${props => (props.selected ? 'background: rgba(255,255,255,0.3)' : null)}
`;

const LANG_SELECTOR = {
  en,
  ja: jp,
};

export default function Navigation({ langs, langKey }) {
  const links = langs.map(lang => (
    <LangLi key={lang.langKey} selected={lang.selected}>
      <Link to={lang.link}>
        <img width={30} src={LANG_SELECTOR[lang.langKey]} alt={lang.langKey} />
      </Link>
    </LangLi>
  ));
  return (
    <Header>
      <Wrapper>
        <h1>
          <Link to={`/${langKey !== 'en' ? `${langKey}/` : '/'}`}>
            <Image />
          </Link>
        </h1>
        <Nav>
          <li>
            <Link to={`/${langKey !== 'en' ? langKey : ''}/careers/`}>Careers 🏔</Link>
          </li>
          {/* <li>
            <Link to="/download">Download 🤖</Link>
          </li> */}
          {links}
        </Nav>
      </Wrapper>
    </Header>
  );
}

Navigation.propTypes = {
  langs: PropTypes.array.isRequired,
  langKey: PropTypes.string.isRequired,
};
